import {AuthContext} from './authcontext'
import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify'

let getUserInStorage = () => {
    const tokenString = localStorage.getItem('User');
    const userToken = JSON.parse(tokenString);
    return userToken
  }

export default function AuthProvider({ children }) {
    let [user, setUser] = React.useState(getUserInStorage());

    // useEffect(() => {
    //     const userInStorage = localStorage.getItem('User');
    //     const userParsed = JSON.parse(userInStorage);
    //     setUser(userParsed);
    //   }, []);

    useEffect(() => {
        localStorage.setItem("User", JSON.stringify(user));
      }, [user]);

    let signIn = async (username, password) => {
      try {
          const user = await Auth.signIn(username, password);
          setUser(user);
        } catch (error) {
          throw(error);   
        }

    }

    let signOut = async () => {
      try {
        await Auth.signOut();
        setUser(null);
      } catch (error) {
          throw(error);
      }
    }

    let value = { user, setUser, signIn, signOut };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }