
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function Privacy({ title, description, background, fillValues, percentage, whole, children }) {
  
  const {t} = useTranslation();

  return (
    <div className='paddingSection'>
    <h1>{t('Privacy Policy')}</h1> 
    <h3>{t('Sparar Saldio.se information om dig som besöker webbplatsen?')}</h3>
    <p>{t('Om du inte väljer att abonnera på nyhetsbrevet sparar Saldio ingen information om dig. Vi använder inga cookies.')}</p>
    <h3>{t('Sparar Saldio.se information om dig om du väljer att abonnera på nyhetsbrevet?')}</h3>
    <p>{t('Om du väljer att abonnera på vårt nyhetsbrev sparar vi din e-postadress så länge som du väljer att fortsätta abonnera på nyhetsbrevet.')}</p>
    <p>{t('Vi delar inte din e-postadress med någon annan.')}</p>
    <p>{t('E-postadressen används endast för att skicka mejl till dig med nyheter om Saldios digitala remsräknare, och för inget annat syfte.')}</p>
    <h3>{t('Hur kan jag ta bort min e-postadress från Saldios system?')}</h3>
    <p>{t('Du kan närsomhelst välja att sluta abonnera på nyhetsbrevet genom att klicka på länken för att sluta abonnera i mejlen du får av oss. Du kan även kontakta oss på info@saldio.se för att avanmäla dig från nyhetsbrevet.')}</p>
    <p>{t('Om du väljer att sluta abonnera på nyhetsbrevet raderar vi din e-postadress från vårt system.')}</p>
    {/* <p>Den här webbplatsen använder cookies som placerats på webbsidan av tredje part.</p>  
    <h2>Vad är cookies?</h2>
    <p>Cookies är textfiler som placeras på din dator och som till exempel samlar information om hur du använder en webbplats. De kan också spara inställningar om språk eller utseende du har gjort på webbplatsen. Cookies kan även användas för att hålla dig inloggad på vissa webbplatser där du kan logga in. </p>
    <h2>Vilka Cookies används på webbplatsen?</h2>
    <p>Tredjepartsleverantörer, inräknat Google, använder cookies på websidan procentify.com för att visa annonser baserat på användarnas tidigare besök på webbplatsen eller på andra webbplatser. 
    Dessa annonscookies gör att annonserna kan anpassas till dig som besöker sidan. Syftet med annonserna är att finansiera utvecklingen av sidan procentify.com.</p>
    <p>Webbplatsen Procentify.com placerar inga egna cookies på din dator, utan tillåter endast att Google använder cookies som en del av sin annonseringstjänst.</p>
    <h2>Hur kan jag välja bort Cookies?</h2>
    <p>När du besöker sidan får du välja om du godkänner, eller inte godkänner användandet av cookies.</p>
    <p>Du som användare kan även välja bort visning av Googles anpassade annonser på denna och andra webbplatser via deras annonsinställningar på sidan <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated.</a> Där kan du även se vilka antaganden om dig som Google redan gör baserat på din aktivitet på denna och andra webbplatser.</p>
    <p>Det är även möjligt att välja bort tredjepartsleverantörers cookies för anpassade annonser genom att besöka www.aboutads.info.
    </p> */}
    <h3>{t('Vad har jag för rättigheter angående min data?')}</h3>
    <p>{t('Du har rätt att få en kopia av din personliga information som finns hos Saldio. Du har även rätt att be oss korrigera personlig information som kan ha blivit felaktig samt att få din personliga information hos oss raderad.')}
    {t('Du har även under vissa förutsättningar rätt att be oss begränsa användningen av din data, att be oss inte använda datan samt under vissa förutsättningar även rätt att få datan överförd till dig eller till en annan organisation.')}
    </p>
    <h3>{t('Ändringar av integritetspolicyn')}</h3>
    <p>{t('Vi utvärderar vår integritetspolicy löpande och publicerar alla uppdateringar på denna sida.')}</p>
    <h3>{t('Vem kontaktar jag om jag har frågor?')}</h3>
    <p>{t('Om du har frågor om vår integritetspolicy är du välkommen att kontakta oss på mejladressen info@saldio.se eller via brev till mig som utvecklat sidan: Elin Fritiofsson, Hov 603, 312 94 Laholm.')}</p>
    <p>{t('Om du inte är nöjd med hur saldio.se behandlar dina personuppgifter eller med svaren du får av oss har du rätt att lämna klagomål till exempelvis Datainspektionen, som är ansvarig tillsynsmyndighet.')}</p>
    <Link to="/" style={{ color: 'inherit'}}>{t('Tillbaka till startsidan')}</Link>
    </div>
  );
}
