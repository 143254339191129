import { useTranslation } from 'react-i18next'
import {Link} from 'react-router-dom'
import mainScreenshot from '../screenshot.png'
import addMultiply from '../add-multiply.jpg'
import multiplyDivide from '../multiply-divide.jpg'
import percent from '../percent.jpg'
import sumGrandTotal from '../sumGrandTotal.jpg'
import { useState } from 'react'
import personWorking from '../person_working.jpg'
import calculatorImage from '../remsraknare.jpg'


export default function LandingPreLaunch(){

    const {t} = useTranslation();

    const [displayThanks, setDisplayThanks] = useState('none');
    const [email, setEmail] = useState('');

    const [subscribeMessage, setSubscribeMessage] = useState(''); // "Tack för din anmälan! Vi har skickat ett bekräftelsemejl till dig.";

    const subscribe = async () => {

      console.log(process.env.NODE_ENV);

      if (!(/^\S+@\S+\.\S+$/.test(email))){
        setSubscribeMessage(t("Ogiltig e-postadress"));
        setDisplayThanks('block');
        return;
      }

      //call subscription API here...
      await fetch(process.env.REACT_APP_API_URL, {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          lang: process.env.REACT_APP_LANG,
        }),
      })
      setSubscribeMessage(t("Tack för din anmälan! Vi har skickat ett bekräftelsemejl till dig"));
      setDisplayThanks('block');
    }

    return(
      <div className='allContent'>
        <div className='landingContainer section'>
          <div className="leftLeading">
            <h1>{t("Digital remsräknare")}</h1>
            <p>{t("Saldios remsräknare är en digital version av en traditionell remsräknare för webbläsaren och mobilen.")}</p>
            <p>{t("Vill du få en inbjudan när remsräknaren finns tillgänglig?")}</p>
        
            {/* <div className="first-layout">
            <p>Portable</p>
            <p>Easy to use</p>
            <p>Both desktop and mobile</p>
          </div> */}
          <div className='subscriptionSection'>
          <input type="text" placeholder={t('Din e-postadress')} value={email} onChange={(e) => setEmail(e.target.value)}></input>
          <button className="App-button" onClick={subscribe}>
            {t('Get an invitation')}
          </button>
        </div>  
        <em>
        <div style={{marginTop: '5px'}}>* {t('Information om hur vi behandlar din data hittar du i vår')} <Link
        to={'/privacy'}
      >{t('integritetspolicy')}</Link>.
        </div>
        <div className="thanksForSubscribing" style={{display: displayThanks, marginTop: '10px'}}>{subscribeMessage} 
       
          </div>
        </em>
        </div>
        <div className='rightLeading'>
          <img className="mainScreenshot" alt='screenshot of Saldio keys' src={mainScreenshot}/>
        </div>
       
      </div>
      <div className="section paddingSection">
        <img alt='person working' src={personWorking}></img>
          <div className="infotext">
       <h2>{t('Enkel att använda')}</h2>
       <p>{t('Med Saldio gör du snabba uträkningar direkt på tangentbordet. Du kan snabbt räkna med alla fyra räknesätten och följa dina uträkningar på remsan på skärmen. Du behöver aldrig klicka med muspekaren om du inte vill och kan räkna snabbt och lätt.')}
       {t('Remsräknarens inmatningsfält formatterar tusental medan du skriver så att de är lätta att läsa och följer svenskt format. Med enkla knapptryck kan du ångra och göra om den senaste uträkningen.')}</p>
        <p>{t('Saldio finns alltid tillgänglig online i webbläsaren var du än befinner dig. Perfekt för din redovisningsbyrå, butik eller för privata uträkningar!')}</p>
        </div>
       <div>
       </div>
       </div>
       <div className="coloredSection paddingSection">
        <div className="functionsWrapper">
       <h2>{t('Funktioner')}</h2>

        <div className='first-layout'>
          <div>
          <img className="smallImage" alt='exemple of adding & subtracting' src={addMultiply}/>
            <p>{t('Addera och subtrahera')}</p>
          </div>
          <div>
          <img className="smallImage" alt='example of multiplying & dividing' src={multiplyDivide}/>
  
            <p>{t('Multiplicera och dividera')}</p>
          </div>
          <div>
          <img className="smallImage" alt='exemple of calculating percents' src={percent}/>
            <p>{t('Procenträkning')}</p>
          </div>
          <div>
            <img className="smallImage" alt='example of sum & grand total' src={sumGrandTotal}/>
            <p>{t('Summa och Grand Total (GT)')}</p>
          </div>
        </div>
        </div>
        </div>
        <div className="paddingSection section ">
        <div className='infotext'>

        <h2>{t('Vad är en remsräknare?')}</h2>
          <p>{t('En remsräknare, som även kan kallas bordsräknare med remsa, är en räknare där man lätt kan addera och subtrahera tal samt använda de andra räknesätten och procenträkning.')}
          {t('Man ser även sina uträkningar på en remsa som skrivs ut medan man räknar.')}
          {t('Remsräknare är speciellt lämpade för bokföring och kan även användas i exempelvis butiker för att snabbt och lätt beräkna summor och samtidigt se hela beräkningen.')}
          {t('Berömda märken för fysiska remsräknare är Casio och Facit.')}</p>
          <p>{t('Saldio är en digital remsräknare som går att använda direkt i webbläsaren.')}</p>
         </div>
         <img alt='physical calculator' src={calculatorImage}></img>
         </div>
  </div>
    )
}