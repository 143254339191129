import './style/App.scss';
import Login from './login-components/login'
import Dashboard from './login-components/dashboard'
import SignUp from './login-components/signup';
import Amplify from 'aws-amplify';
// import awsconfig from './aws-exports';
import configi18n from './localization/i18n-config';
import Header from './page-components/header'
import Mainpart from './page-components/mainpart'
import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AuthProvider from './auth/authprovider'
import ProtectedRoute from './login-components/protectedroute';
import PasswordReset from './login-components/passwordreset';
import VerifyCode from './login-components/verifycode';
import Footer from './page-components/footer';
import LandingPreLaunch from './login-components/landingPreLaunch';
import Privacy from './privacy_policy/privacy';
import Impressum from './impressum/impressum';

// Amplify.configure(awsconfig);


configi18n()

function App() {

  return (    
    <div className="App">
       
    <BrowserRouter>
    <AuthProvider>
    <Header/>
    <Mainpart>
    <Routes>
     <Route path="/" element={<LandingPreLaunch />} />
       {/* <Route path="login" element={<Login />} /> */}
       <Route path="privacy" element={<Privacy />} />
       <Route path="impressum" element={<Impressum />} />

       {/* <Route path="resetPassword" element={<PasswordReset/>}/>
       <Route path="signup" element={<SignUp />} />
       <Route path="verifycode" element={<VerifyCode />} /> */}
       <Route path="dashboard" element={<ProtectedRoute><Dashboard/> </ProtectedRoute>} />
   </Routes>
   <Footer/>
   </Mainpart>

   </AuthProvider>

 </BrowserRouter>

 
 </div>

  );
}

export default App;
