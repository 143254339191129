
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function Impressum({ title, description, background, fillValues, percentage, whole, children }) {
  
  const {t} = useTranslation();

  return (
    <div className='loneSection'>
    <h1>{t('Impressum')}</h1> 
    <h3>Addresse: Elin Fritiofsson, Hov 603, 31294 Laholm, Schweden</h3>
    <h3>E-mail: info@saldio.se</h3>
  
    <Link to="/" style={{ color: 'inherit'}}>{t('Tillbaka till startsidan')}</Link>
    </div>
  );
}
