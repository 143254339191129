import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Footer() {

    const {t} = useTranslation();

    return(
      <footer >
          
        <p>&#169; Saldio 2023</p> 
        <Link
          style={{ display: "block", margin: "1rem 0" }}
          to={'/privacy'}>
          {t('Privacy Policy')}
        </Link>
        {process.env.REACT_APP_LANG === 'de' ? 
         <Link
          style={{ display: "block", margin: "1rem 0" }}
          to={'/impressum'}>
          {t('Impressum')}
        </Link> : 
        ''}
      </footer>
    )
}