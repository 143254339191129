import {Navigate, useLocation} from 'react-router-dom'
import React from 'react';
import {AuthContext} from '../auth/authcontext'


function useAuth() {
    return React.useContext(AuthContext);
  }

export default function ProtectedRoute({children}){

    let auth = useAuth();
    let location = useLocation();

    if (!auth.user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
      }

    return children;

}