import React from 'react';
import {useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {AuthContext} from '../auth/authcontext'

export default function Dashboard({setUser,user}) {

    const auth = useContext(AuthContext);

    const {t} = useTranslation();

    const navigate = useNavigate();

    let buttonClick = async () => {

        async function signOut() {
            try {
                await auth.signOut();
                navigate("/login");
               // setUser(null);
            } catch (error) {
                console.log('error signing out: ', error);
               //setUser(null);
            }
        }
        await signOut();
    }

  return(
    <div>
      <h1>Hej {auth.user.username}</h1>
      <button className="App-button" onClick={buttonClick}>sign out</button>
      <p>{t("This is signed-in mode where you can buy things, try a full product, or save user data while working. You still haven't bought/subscribed to anything")}</p>
      <p>Put test/free product here:</p>
    </div>
  )
}