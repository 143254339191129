import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'
import { AuthContext } from '../auth/authcontext';
import Logo from '../logo.svg'
import SubscribeButton from './subscribebutton.';


export default function Header(props) {

    const {t} = useTranslation();
    const auth = useContext(AuthContext);
    return(
  <div>
        <header className="App-header">

        <Link //style={{ display: "block", margin: "1rem 0" }}
            to={`/`}>
            <img src={Logo} alt={"Saldios Logo"} style={{width: '120px'}}/>
            </Link>

            <div className="headerButtons">
      {/* {auth.user ?  <div>30 dagar kvar  <div>
          
        <SubscribeButton />
          </div></div>
      : <div><Link className="App-button"
            //style={{ display: "inline-block", margin: "1rem 0" }}
            to={`login`}>
           {t('Log in')}
          </Link>
          <Link className="App-button"
            //style={{ display: "inline-block", margin: "1rem 0" }}
            to={`signup`}>
            {t('Register')}
          </Link></div>} */}
          </div>
  
          </header>
         
    </div>
    )

}
